/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7df3e1',
  },
  cls1: {
    fill: "#c1b16d"
  },
  cls2: {
    fill: "#2b1b1f"
  },
  cls3: {
    fill: "#070808"
  },
  cls4: {
    fill: "#5b2433"
  },
  cls5: {
    "clip-path": "url(#clippath)"
  },
  cls6: {
    fill: "none"
  },
  cls7: {
    fill: "#833e52"
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 77.7605 77.76">

      <defs>
        <clipPath id="clippath"><rect className={classes.cls6} y=".2955" width="77.7605" height="77.7605" /></clipPath>
      </defs>
      <g className={classes.cls5}>
        <path className={classes.cls2} d="M-11.7318,87.2293c-.2729-6.1291,.1029-17.4375,6.7274-22.2568,1.8666-1.3579,5.7403-3.2999,7.6107-4.2376,0,0,4.7233-2.3679,9.5068-4.2079,10.696-4.1143,18.6739-7.1831,27.897-6.5457,10.1673,.7026,8.9955,5.0209,26.1827,8.5717,3.2968,.6811,6.8744,1.2524,11.0653,3.7404,1.861,1.1048,7.8223,4.6437,9.9744,11.2211,.9136,2.7923,.3148,3.6081,.9351,8.5717,.6293,5.0355,1.852,9.049,2.8053,11.6887-34.7544,.8831-69.5088,1.7663-104.2632,2.6494,.5195-3.065,1.039-6.1301,1.5585-9.1951Z" />
        <path className={classes.cls4} d="M77.2582,62.2934c-4.1909-2.4879-7.7685-3.0593-11.0653-3.7404-17.1872-3.5509-16.0153-7.8691-26.1827-8.5717-9.2232-.6374-17.2011,2.4314-27.8971,6.5457-4.7835,1.84-9.5067,4.2079-9.5068,4.2079-1.8704,.9377-5.7441,2.8797-7.6107,4.2376-4.9712,3.6165-6.4234,10.8871-6.7233,16.8812,1.1448-3.4964,2.3773-9.7558,7.124-14.6107C.0237,62.5102,5.5202,61.4958,12.3045,60.1847c15.6083-3.0164,37.3058-1.175,39.2524-1.9942,.0952-.0401,.6258-.2896,1.2536-.1654,.7755,.1534,1.2423,.7865,1.3407,.9058,1.802,2.1845,20.2157,1.9621,25.8001,7.6503,.1616,.1646,.4269,.4439,.6183,.6618,1.6022,1.8242,3.3373,4.4685,4.8227,6.7321,.921,1.4036,1.7275,2.7067,2.4468,3.9211-.0549-1.6752-.0394-2.6482-.6065-4.3816-2.152-6.5774-8.1134-10.1163-9.9744-11.2211Z" />
        <path className={classes.cls3} d="M35.023,26.9156c-4.9097,1.4073-8.7278,5.9773-16.3642,15.1174-3.2984,3.9479-4.6061,6.1723-4.8313,9.0393-.3648,4.643,2.26,9.0524,5.143,11.5329,1.9923,1.7141,4.2102,2.595,6.7015,3.5845,3.6597,1.4536,4.3644,.7522,6.3898,2.026,3.2282,2.0304,4.5054,5.4979,5.1353,7.2082,.9187,2.4942,1.0858,4.6978,1.091,6.0954,.3859-2.2391,1.0126-4.0152,1.5455-5.2662,.2847-.6683,1.7093-3.9256,4.8935-7.4322,.1417-.1561,.2472-.2689,.2702-.2934,2.699-2.8815,3.877-2.6093,8.1042-5.7664,4.0534-3.0273,7.744-5.7836,9.5068-10.1302,2.5279-6.2331-.1883-12.581-.6234-13.5589-4.2771-9.6127-17.1259-14.9757-26.9619-12.1563Z" />
        <path className={classes.cls4} d="M37.6724,4.0057c-2.5887,.2473-4.447,1.8624-6.0786,3.4855-6.6335,6.5989-10.2246,14.0307-11.6882,17.2425-2.03,4.4548-4.6806,10.3759-6.1348,18.6947-1.1768,6.732-1.8799,10.8039,.3683,14.9687,2.5723,4.7652,7.6211,7.1522,8.2612,7.4456,3.6362,1.6664,5.4147,.9559,8.5814,2.7372,3.299,1.8557,5.1708,4.7657,6.2154,6.8439-1.3107-4.2599-3.5774-6.1611-4.6619-6.9179-1.1831-.8256-2.4843-1.3535-4.2537-2.0714-2.5844-1.0485-4.2363-1.408-5.1414-1.7015-4.0287-1.306-7.7651-6.0025-8.3776-10.0763-1.1868-7.8939,9.2084-14.4228,11.0653-15.5849,9.2617-5.7964,21.4518-5.9418,29.7672-.9351,1.9972,1.2025,6.7086,4.0839,7.3249,8.8834,.3803,2.9619-.9567,5.4459-1.749,6.9178-2.0246,3.7614-5.3345,6.0601-8.8488,8.5113-.8573,.598-2.6006,1.7669-4.7697,3.5784-.9826,.8206-1.9876,1.7189-3.0701,2.9591-.7937,.9095-1.3316,1.6619-1.7015,2.1823-.7074,.9953-1.5883,2.3533-2.4666,4.0642,.9893-1.3273,2.5374-3.246,4.6829-5.3033,3.5224-3.3775,5.7459-4.3307,8.7276-6.5457,4.5592-3.3869,9.3956-6.9797,10.7536-12.6238,.9917-4.1217-.0699-8.5131-1.4026-14.0264-.2931-1.2123-.6918-2.682-1.2468-4.3638-1.3403-4.0615-5.2434-15.4422-15.3244-24.1234-4.628-3.9854-7.0819-4.4083-8.8323-4.2411Z" />
        <path className={classes.cls7} d="M63.0758,36.7341c-.293-1.2123-.6917-2.682-1.2468-4.3638-1.3403-4.0614-5.2434-15.4422-15.3244-24.1235-4.6281-3.9854-7.0819-4.4083-8.8323-4.2411-2.5887,.2472-4.447,1.8624-6.0786,3.4855-6.6335,6.5989-10.2246,14.0307-11.6882,17.2425-.9223,2.0239-1.9721,4.3549-2.9802,7.0714,1.7193-2.5975,3.3128-4.9805,4.755-7.1224,4.3598-6.4748,5.5103-8.0324,7.4797-9.85,2.2792-2.1035,4.0669-3.7534,6.798-4.4372,5.0511-1.2646,9.7069,1.6822,11.4797,2.8043,6.0182,3.8092,8.9442,9.9105,14.1692,20.8061,.7494,1.5626,1.3321,2.8566,1.7011,3.6907-.0763-.3173-.1537-.6376-.2323-.9625Z" />
        <path className={classes.cls1} d="M33.8282,45.472c-.4381,.9067-1.4151,1.4332-2.4134,1.3004s-1.8037-.8962-1.9896-1.886c.4381-.9067,1.4151-1.4331,2.4134-1.3004s1.8037,.8962,1.9896,1.886Z" />
        <path className={classes.cls1} d="M49.3423,44.7047c-.1031,1.0017-.8424,1.8294-1.8262,2.0446s-2.0011-.2284-2.5129-1.0956c.1031-1.0017,.8424-1.8294,1.8262-2.0446s2.0011,.2284,2.5129,1.0956Z" />
        <g>
          <path className={classes.cls2} d="M15.1197,50.5319s.0007,.0002,.001,.0003c.0021-.0064,.0036-.0127,.0058-.0191-.0022,.0066-.0047,.0122-.0068,.0187Z" />
          <polygon className={classes.cls2} points="62.6357 50.5375 62.6343 50.5427 62.6347 50.5426 62.6357 50.5375" />
          <path className={classes.cls2} d="M55.5951,38.1367c1.9601,1.1802,6.525,3.9821,7.2768,8.6227-.1027-1.4867-.4624-3.1996-1.3613-4.9135-1.7458-3.3287-4.5312-4.7783-7.1876-6.1608-8.0216-4.1748-15.9294-2.7188-17.7407-2.3388-2.8894,.6062-5.0075,1.5672-6.9594,2.4529-3.0884,1.4013-6.3245,2.8697-9.3161,5.9688-1.3736,1.423-3.6451,4.1852-5.1189,8.577,2.0803-5.792,9.4618-10.5358,10.6399-11.2732,9.2617-5.7964,21.4519-5.9418,29.7672-.9351Z" />
        </g>
      </g>
    </svg>
  );
};

export default LogoIcon;
