import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { techRadarApiRef } from '@backstage/plugin-tech-radar';
import { TechRadarReader } from './lib/techradar/techRadarReader';


import { costInsightsApiRef } from '@backstage/plugin-cost-insights';
import { ClassicAWSCostsClient } from './lib/costexplorer/classicawscostsclient';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: costInsightsApiRef,
    deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
    factory: ({ discoveryApi, identityApi }) => new ClassicAWSCostsClient({discoveryApi, identityApi}),
  }),
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory(techRadarApiRef, new TechRadarReader()),
  ScmAuth.createDefaultApiFactory(),
];
