
import {
  HomePageToolkit,
  HomePageCompanyLogo,
  HomePageStarredEntities,
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import {
  MockStarredEntitiesApi,
} from '@backstage/plugin-catalog-react';
import {
  HomePageSearchBar,
} from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import LogoFull from '../Root/LogoFull';


const starredEntitiesApi = new MockStarredEntitiesApi();
starredEntitiesApi.toggleStarred('component:default/example-starred-entity');
starredEntitiesApi.toggleStarred('component:default/example-starred-entity-2');
starredEntitiesApi.toggleStarred('component:default/example-starred-entity-3');
starredEntitiesApi.toggleStarred('component:default/example-starred-entity-4');

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 150,
  },
}));

export const HomePage = () => {
  const classes = useStyles();
  const { svg, container } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo
              className={container}
              logo={<LogoFull className={svg} />}
            />
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                classes={{ root: classes.searchBar }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit
                  title="Company Links"
                  tools={[
                    {
                      url: 'https://dev.azure.com/classicgroup/',
                      label: 'Azure DevOps',
                      icon: <img style={{ height: 50 }} src="https://user-images.githubusercontent.com/1874103/46415699-cfc5cf00-c6f3-11e8-9bcc-2fc2bf1759ec.png" alt="Classic Lunchroom" />,
                    },
                    {
                      url: 'https://app.lunchroom.nz/',
                      label: 'Lunchroom',
                      icon: <img style={{ height: 50 }} src="https://app.lunchroom.nz/assets/svg/cg-lunchroom.svg" alt="Classic Lunchroom" />,
                    },
                    {
                      url: 'https://classicgroupnz.sharepoint.com/sites/ClassicTechnology',
                      label: 'CTech Sharepoint',
                      icon: <img style={{ height: 50 }} src="https://classicgroupnz.sharepoint.com/_api/siteiconmanager/getsitelogo?target=3&hash=%27637686560076334277%27" alt="Company Intranet" />,
                    },

                    {
                      url: 'https://classicgroupnz.sharepoint.com/',
                      label: 'Classic Group Sharepoint',
                      icon: <img style={{ height: 50 }} src="https://classicgroupnz.sharepoint.com/_api/siteiconmanager/getsitelogo?target=3&hash=%27637686560076334277%27" alt="Company Intranet" />,
                    },
                  ]}
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <InfoCard title="Composable Section">
                  <div style={{ height: 370 }} />
                </InfoCard>
              </Grid> */}
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};