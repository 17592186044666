import { ChangeStatistic, DateAggregation, Trendline } from "@backstage/plugin-cost-insights-common";
import regression, { DataPoint } from 'regression';

export function changeOf(aggregation: DateAggregation[]): ChangeStatistic {
    const firstAmount = aggregation.length ? aggregation[0].amount : 0;
    const lastAmount = aggregation.length
        ? aggregation[aggregation.length - 1].amount
        : 0;

    // if either the first or last amounts are zero, the rate of increase/decrease is infinite
    if (!firstAmount || !lastAmount) {
        return {
            amount: lastAmount - firstAmount,
        };
    }

    return {
        ratio: (lastAmount - firstAmount) / firstAmount,
        amount: lastAmount - firstAmount,
    };
}

export function trendlineOf(aggregation: DateAggregation[]): Trendline {
    const data: ReadonlyArray<DataPoint> = aggregation.map(a => [
        Date.parse(a.date) / 1000,
        a.amount,
    ]);
    const result = regression.linear(data, { precision: 5 });
    return {
        slope: result.equation[0],
        intercept: result.equation[1],
    };
}