
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import {
  CostInsightsApi,
  ProductInsightsOptions,
  Alert,

} from '@backstage/plugin-cost-insights';

import { DateTime } from "luxon";


import {
  Cost,
  Entity,
  Group,
  Project,
  MetricData,
} from '@backstage/plugin-cost-insights-common';
import { changeOf, trendlineOf } from './costutils';



export class ClassicAWSCostsClient implements CostInsightsApi {

  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async _callApi(method: string, params: { [id: string]: string }): Promise<any> {
    const url = `${await this.discoveryApi.getBaseUrl('costs')}/${method}`;
    const { token: idToken } = await this.identityApi.getCredentials();
    const response = await fetch(`${url}?${new URLSearchParams(params)}`, {
      method: 'GET',
      headers: {
        ...(idToken && { Authorization: `Bearer ${idToken}` }),
      },
    });

    if (!response.ok) {
      throw new Error(`${response.status} returned from server calling ${url}`);
    }
    const toReturn = await response.json();

    return toReturn.data;
  }

  async getLastCompleteBillingDate(): Promise<string> {
    // return yesterday!    
    const nowMinus1Day = DateTime.now().minus({ days: 1 });
    return nowMinus1Day.toFormat('yyyy-LL-dd');
  }


  async getUserGroups(_userId: string): Promise<Group[]> {

    return [
      { id: 'ctech', name: 'CTech' },
    ];
  }

  async getGroupProjects(group: string): Promise<Project[]> {
    return await this._callApi("getGroupProjects", { group });
  }

  async getAlerts(_group: string): Promise<Alert[]> {
    return [];
  }

  async getDailyMetricData(_metric: string, _intervals: string): Promise<MetricData> {
    return {
      id: 'remove-me',
      format: 'number',
      aggregation: [],
      change: {
        ratio: 0,
        amount: 0
      }
    }
  }

  async getGroupDailyCost(group: string, intervals: string): Promise<Cost> {

    const data = await this._callApi("getGroupDailyCost", { group, intervals });

    return {
      ...data,
      change: changeOf(data.aggregation),
      trendline: trendlineOf(data.aggregation),
    }

  }

  async getProjectDailyCost(project: string, intervals: string): Promise<Cost> {
    const data = await this._callApi("getProjectDailyCost", { project, intervals });

    return {
      ...data,
      change: changeOf(data.aggregation),
      trendline: trendlineOf(data.aggregation),
    }

  }

  async getCatalogEntityDailyCost(catalogEntityRef: string, intervals: string): Promise<Cost> {
    const data = await this._callApi("getCatalogEntityDailyCost", { catalogEntityRef, intervals });

    return {
      ...data,
      change: changeOf(data.aggregation),
      trendline: trendlineOf(data.aggregation),
    }
  }

  async getProductInsights(_options: ProductInsightsOptions): Promise<Entity> {
    return {
      id: 'remove-me',
      aggregation: [0, 0],
      change: {
        ratio: 0,
        amount: 0
      },
      entities: {}
    }
  }
}